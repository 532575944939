import React, { useCallback } from "react";
import { useMemo } from "react";
import { CgUser } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  UserScopeEnum,
  ConditionModeEnum,
  MatchModeEnum,
  useAdminStripeOnboardingSubscriptionsQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { formatDatetime } from "../../services/formatDatetime";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface AdminStripeSubscriptionsListData extends FilterBaseData {
  id: string;
  userId: string;
  stripeSessionId: string;
  email: string;
  source: string;
  isUsed: boolean | null;
  startDate: Date | null;
  endDate: Date | null;
}

export const AdminStripeSubscriptionsListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  // parse filter url parameters
  const params = useUrlParams<AdminStripeSubscriptionsListData>((params) => ({
    id: params.id ?? "",
    userId: params.userId ?? "",
    stripeSessionId: params.stripeSessionId ?? "",
    email: params.email ?? "",
    source: params.source ?? "",
    startDate: params.startDate ? new Date(params.startDate) : null,
    endDate: params.endDate ? new Date(params.endDate) : null,
    isUsed: params.isUsed !== undefined ? (params.isUsed === "true" ? true : false) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  // fetch items
  const { data, loading, error } = useAdminStripeOnboardingSubscriptionsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        id: params.id.length > 0 ? params.id : undefined,
        userId: params.userId.length > 0 ? params.userId : undefined,
        stripeSessionId: params.stripeSessionId.length > 0 ? params.stripeSessionId : undefined,
        email: params.email.length > 0 ? params.email : undefined,
        source: params.source.length > 0 ? params.source : undefined,
        isUsed: params.isUsed ?? undefined,
        startDate: params.startDate ? formatApiDate(params.startDate) : undefined,
        endDate: params.endDate ? formatApiDate(params.endDate) : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  // get items
  const stripeOnboardingSubscriptions = useMemo(
    () => data?.admin.stripeOnboardingEmails.stripeOnboardingEmails ?? [],
    [data?.admin.stripeOnboardingEmails.stripeOnboardingEmails],
  );

  const total = data?.admin.stripeOnboardingEmails.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "id",
        label: "ID",
        defaultValue: params.id,
      },
      {
        field: "text",
        type: "text",
        name: "userId",
        label: "User id",
        defaultValue: params.userId,
      },
      {
        field: "text",
        type: "text",
        name: "stripeSessionId",
        label: "Checkout id",
        defaultValue: params.stripeSessionId,
      },
      {
        field: "text",
        type: "text",
        name: "email",
        label: "Email",
        defaultValue: params.email,
      },
      {
        field: "text",
        type: "text",
        name: "source",
        label: "Source",
        defaultValue: params.source,
      },
      {
        field: "date",
        name: "startDate",
        label: "Created after",
        defaultValue: params.startDate,
      },
      {
        field: "date",
        name: "endDate",
        label: "Created before",
        defaultValue: params.endDate,
      },
      {
        field: "radio",
        name: "isUsed",
        label: "Is used",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.isUsed !== null ? (params.isUsed ? "true" : "false") : null,
      },
    ],
    [
      params.id,
      params.userId,
      params.stripeSessionId,
      params.email,
      params.source,
      params.startDate,
      params.endDate,
      params.isUsed,
    ],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "hash",
      },
      {
        label: "user",
      },
      {
        label: "Stripe email",
      },
      {
        label: "Checkout session",
      },
      {
        label: "Is used",
      },
      {
        label: "Source",
      },
      {
        label: "Expiry date",
      },
      {
        label: "Created",
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      stripeOnboardingSubscriptions.map((stripeOnboardingSubscription) => {
        const isUsed = stripeOnboardingSubscription.isUsed;
        const isExpired = new Date(stripeOnboardingSubscription.expiryDate) < new Date();
        return {
          id: stripeOnboardingSubscription.id,
          cells: [
            {
              content: stripeOnboardingSubscription.id,
              strikeThrough: !isUsed && isExpired,
            },
            {
              content: stripeOnboardingSubscription.userId ? (
                <Link
                  to={buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "users",
                    page: "user",
                    id: stripeOnboardingSubscription.userId,
                  })}
                >
                  <CgUser size={30} />
                </Link>
              ) : (
                <></>
              ),
            },
            {
              content: stripeOnboardingSubscription.email,
            },
            {
              content: stripeOnboardingSubscription.stripeSessionId,
              wordBreak: true,
            },
            {
              content: isUsed ? "Yes" : isExpired ? "expired" : "No",
              colorRed: !isUsed && isExpired,
            },
            {
              content: stripeOnboardingSubscription.source ?? "n/a",
            },
            {
              content: formatDatetime(stripeOnboardingSubscription.expiryDate),
              colorRed: !isUsed && isExpired,
            },
            {
              content: formatDatetime(stripeOnboardingSubscription.createdDate),
            },
          ],
          actions: [],
        };
      }),
    [stripeOnboardingSubscriptions],
  );

  // // data table bulk actions
  // const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // // header buttons configuration
  // const viewActions: Action[] = useMemo(() => [], []);

  // // handle filters submit
  const onFilterSubmit = useCallback(
    (data: AdminStripeSubscriptionsListData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "stripes" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching stripe email data failed" error={error} />;
  }

  // render view
  return (
    <View scrollable>
      <Filter
        title="Stripe Subscriptions"
        fields={filters}
        loading={loading}
        matchMode={params.matchMode}
        conditionMode={params.conditionMode}
        onSubmit={onFilterSubmit}
      />
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        stats={{ resultCount: total, pageCount }}
        openAuthorizedScopes={[
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_PAYMENTS,
          UserScopeEnum.ADMIN_PAYMENTS_INFO,
        ]}
        // onOpen={(row) =>
        //   navigate({
        //     pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
        //       menu: "payments",
        //       page: "payment",
        //       modifier: row.id,
        //     }),
        //   })
        // }
      />
      <Pagination
        sticky
        pageCount={pageCount}
        currentPage={params.page}
        onPageChange={(page) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "stripes" }),
            search: getUrlSearchParamsString({ ...params, page }),
          })
        }
      />
    </View>
  );
};
