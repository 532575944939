import { sentenceCase } from "change-case";
import React, { useCallback } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { SelectFieldOption } from "../../components/SelectField/SelectField";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  UserScopeEnum,
  ConditionModeEnum,
  MatchModeEnum,
  useAdminPaymentsQuery,
  PaymentStatusEnum,
  PaymentProductDataCheckoutIdentifier,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { formatDatetime } from "../../services/formatDatetime";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface AdminPaymentListData extends FilterBaseData {
  adminPaymentId: string;
  adminPaymentSessionId: string;
  attachedUserId: string;
  adminStripeEmail: string;
  adminPaymentProductType: PaymentProductDataCheckoutIdentifier[];
  adminPaymentStatus: PaymentStatusEnum[];
  adminPaymentIsUsed: boolean | null;
  startDate: Date | null;
  endDate: Date | null;
}

export const AdminPaymentListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  // parse filter url parameters
  const params = useUrlParams<AdminPaymentListData>((params) => ({
    adminPaymentId: params.adminPaymentId ?? "",
    adminPaymentSessionId: params.adminPaymentSessionId ?? "",
    attachedUserId: params.attachedUserId ?? "",
    adminStripeEmail: params.adminStripeEmail ?? "",
    adminPaymentProductType: params.adminPaymentProductType
      ? (params.adminPaymentProductType.split(",") as PaymentProductDataCheckoutIdentifier[])
      : [],
    startDate: params.startDate ? new Date(params.startDate) : null,
    endDate: params.endDate ? new Date(params.endDate) : null,
    adminPaymentStatus: params.adminPaymentStatus ? (params.adminPaymentStatus.split(",") as PaymentStatusEnum[]) : [],
    adminPaymentIsUsed:
      params.adminPaymentIsUsed !== undefined ? (params.adminPaymentIsUsed === "true" ? true : false) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  // fetch items
  const { data, loading, error } = useAdminPaymentsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        adminPaymentId: params.adminPaymentId.length > 0 ? params.adminPaymentId : undefined,
        adminPaymentSessionId: params.adminPaymentSessionId.length > 0 ? params.adminPaymentSessionId : undefined,
        attachedUserId: params.attachedUserId.length > 0 ? params.attachedUserId : undefined,
        adminStripeEmail: params.adminStripeEmail.length > 0 ? params.adminStripeEmail : undefined,
        adminPaymentProductType: params.adminPaymentProductType.length > 0 ? params.adminPaymentProductType : undefined,
        adminPaymentIsUsed: params.adminPaymentIsUsed ?? undefined,
        adminPaymentStatus: params.adminPaymentStatus?.length > 0 ? params.adminPaymentStatus : undefined,
        startDate: params.startDate ? formatApiDate(params.startDate) : undefined,
        endDate: params.endDate ? formatApiDate(params.endDate) : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  // get items
  const adminPayments = useMemo(
    () => data?.admin.adminPayments.adminPayments ?? [],
    [data?.admin.adminPayments.adminPayments],
  );

  const total = data?.admin.adminPayments.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "adminPaymentId",
        label: "Payment id",
        defaultValue: params.adminPaymentId,
      },
      {
        field: "text",
        type: "text",
        name: "adminPaymentSessionId",
        label: "Session id",
        defaultValue: params.adminPaymentSessionId,
      },
      {
        field: "text",
        type: "text",
        name: "attachedUserId",
        label: "Attached user id",
        defaultValue: params.attachedUserId,
      },
      {
        field: "text",
        type: "text",
        name: "adminStripeEmail",
        label: "Stripe email",
        defaultValue: params.adminStripeEmail,
      },
      {
        field: "checkbox",
        name: "adminPaymentProductType",
        label: "Product type",
        options: getEnumFormOptions(PaymentProductDataCheckoutIdentifier),
        defaultValue: params.adminPaymentProductType,
      },
      {
        field: "checkbox",
        name: "adminPaymentStatus",
        label: "Status",
        options: getEnumFormOptions(PaymentStatusEnum),
        defaultValue: params.adminPaymentStatus,
      },
      {
        field: "date",
        name: "startDate",
        label: "Show start date",
        defaultValue: params.startDate,
      },
      {
        field: "date",
        name: "endDate",
        label: "Show end date",
        defaultValue: params.endDate,
      },

      {
        field: "radio",
        name: "adminPaymentIsUsed",
        label: "Is used",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.adminPaymentIsUsed !== null ? (params.adminPaymentIsUsed ? "true" : "false") : null,
      },
    ],
    [
      params.adminPaymentId,
      params.adminPaymentSessionId,
      params.attachedUserId,
      params.adminStripeEmail,
      params.adminPaymentProductType,
      params.adminPaymentStatus,
      params.startDate,
      params.endDate,
      params.adminPaymentIsUsed,
    ],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Session id",
      },
      {
        label: "Stripe email",
      },
      {
        label: "Product",
      },
      {
        label: "Is used",
      },
      {
        label: "Status",
      },
      {
        label: "Source",
      },
      {
        label: "Date",
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      adminPayments.map((adminPayment) => {
        return {
          id: adminPayment.id,
          cells: [
            {
              content: adminPayment.paymentSessionId ?? "n/a",
            },
            {
              content: adminPayment.stripeEmail ?? "n/a",
            },
            {
              content: adminPayment.productData.name,
            },
            {
              content: adminPayment.isUsed ? (adminPayment.attachedUserId ? "Yes" : "Detached") : "No",
            },
            {
              content: adminPayment.status.toString(),
            },
            {
              content: adminPayment.stripeSource ?? "n/a",
            },
            {
              content: formatDatetime(adminPayment.createdDate),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_PAYMENTS,
                UserScopeEnum.ADMIN_PAYMENTS_INFO,
              ],
              onClick: (paymentId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "payments",
                    page: "payment",
                    id: paymentId,
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, adminPayments],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // header buttons configuration
  const viewActions: Action[] = useMemo(() => [], []);

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: AdminPaymentListData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "payments" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching payments failed" error={error} />;
  }

  // render view
  return (
    <>
      <View scrollable>
        <Filter
          title="Payments"
          fields={filters}
          actions={viewActions}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_PAYMENTS,
            UserScopeEnum.ADMIN_PAYMENTS_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "payments",
                page: "payment",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "payments" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
    </>
  );
};

export function getEnumProductTypeFormOptions(obj: Record<string, string>): SelectFieldOption[] {
  return Object.values(obj).map((value) => ({
    value,
    label: value === "payment" ? sentenceCase("Gift card") : sentenceCase(value),
  }));
}
