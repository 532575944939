import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { Container } from "../../components/Container/Container";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminPaymentByIdQueryResult, useAdminDetachPaymentMutation } from "../../schema";

export interface ResolvePaymentModalProps extends ModalProps {
  onCancel?: VoidFunction;
  payment: NonNullable<AdminPaymentByIdQueryResult["data"]>["admin"]["adminPayment"];
}

export const DetachPaymentModal: React.FC<ResolvePaymentModalProps> = ({ onCancel, payment, ...rest }) => {
  const formMethods = useForm();
  const { handleSubmit } = formMethods;
  const setToast = useSetRecoilState(toastState);

  const [detachPayment, detachPaymentResult] = useAdminDetachPaymentMutation({
    onError: (_error) => {
      setToast({
        type: ToastType.ERROR,
        title: "Creating a new admin feed poll failed",
        message: `Admin feed poll was not created`,
        isOpen: true,
      });
    },
    onCompleted: (data) => {
      // show success toast message
      if (data.detachPayment) {
        setToast({
          type: ToastType.SUCCESS,
          title: "Created a new admin feed poll",
          message: `Admin feed poll was created`,
          isOpen: true,
        });
      }
    },
  });

  // handle success logic and modal closing logic
  useEffect(() => {
    if (detachPaymentResult.data?.detachPayment && typeof onCancel === "function") {
      onCancel();
    }
  });

  // setup form fields
  const commentField: FormFieldOptions = {
    field: "text",
    type: "text",
    name: "statusComment",
    label: "Comment (optional)",
  };

  // handle attaching payment
  const handleDetachPayment = useCallback(async () => {
    await detachPayment({
      refetchQueries: ["AdminPaymentById"],
      awaitRefetchQueries: true,
      variables: {
        paymentId: payment.id,
      },
    });
  }, [detachPayment, payment.id]);

  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      onClick: handleSubmit(handleDetachPayment),
      loading: detachPaymentResult.loading,
    },
  ];

  return (
    <Modal title="Detach payment from user" actions={modalActions} {...rest}>
      <P center>
        Are you sure you wish to detach this payment? <br></br>
      </P>
      <P center italic marginBottom={5}>
        *this does not remove Revenuecat entry
      </P>
      <FormProvider {...formMethods}>
        <Form>
          <Container overflow key="userInput" expanded>
            <FormField field={commentField} />
          </Container>
        </Form>
      </FormProvider>
    </Modal>
  );
};
