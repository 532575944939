import React, { useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useViewer } from "../../hooks/useViewer";
import { UserScopeEnum } from "../../schema";
import { hasAnyScopes } from "../../services/hasAnyScopes";
import { toggleOption } from "../../services/toggleOption";
import { ReactComponent as InfoIcon } from "../../theme/icons/forward-arrow-icon.svg";
import { ActionsDropdown } from "../ActionsDropdown/ActionsDropdown";
import { Checkbox } from "../Checkbox/Checkbox";
import { Column } from "../Column/Column";
import { DropdownItem, DropdownItemProps } from "../DropdownMenu/DropdownMenu";
import { IconButton } from "../IconButton/IconButton";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import { SingleRow } from "../SingleRow/SingleRow";
import { Table, Tr, Th, Td, TableProps, ThProps, TdProps, TrProps } from "../Table/Table";

export interface DataTableAction<TInfo> extends Omit<DropdownItemProps, "onClick"> {
  label: React.ReactNode;
  authorizedScopes?: UserScopeEnum[];
  loading?: boolean;
  onClick?(info: TInfo): void;
}

export interface DataTableHeader extends ThProps {
  label: React.ReactNode;
}

export interface DataTableCell extends TdProps {
  content: React.ReactNode;
  linkUrl?: string;
  singleRow?: boolean;
  wordWrap?: "break-word" | "normal";
}

export interface DataTableRow extends TrProps {
  id: string;
  cells: DataTableCell[];
  actions?: (DataTableAction<string> | undefined | null)[];
}

export interface DataTableStatistics {
  resultCount: number;
  pageCount: number;
}

export interface DataTableProps extends TableProps {
  headers: DataTableHeader[];
  rows: DataTableRow[];
  loading?: boolean;
  stats?: DataTableStatistics;
  bulkActions?: DataTableAction<string[]>[];
  openAuthorizedScopes?: UserScopeEnum[];
  viewerscopes?: UserScopeEnum[];
  openButtonTitle?: string;
  hideSelectRow?: boolean;
  onOpen?(row: DataTableRow): void;
}

export const DataTable: React.FC<DataTableProps> = React.memo(function DataTable({
  headers,
  rows,
  loading,
  stats,
  bulkActions,
  openAuthorizedScopes,
  openButtonTitle = "Show details",
  hideSelectRow,
  onOpen,
  ...rest
}) {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const areAllRowsSelected = useMemo(() => selectedRowIds.length === rows.length, [rows.length, selectedRowIds.length]);

  // get viewer scopes
  const viewerscopes = useViewer()?.scopes;

  const toggleSelectAll = useCallback(() => {
    // select all if none are selected, otherwise deselect all
    if (!areAllRowsSelected) {
      setSelectedRowIds(rows.map((row) => row.id));
    } else {
      setSelectedRowIds([]);
    }
  }, [areAllRowsSelected, rows]);

  const isBulkActionsEnabled = selectedRowIds.length > 0;

  // handle loading
  if (loading) {
    return (
      <Column center expanded>
        <P small italic>
          Fetching results, please wait...
        </P>
      </Column>
    );
  }

  // handle nothing found
  if (rows.length === 0) {
    return (
      <Column center expanded>
        <P small italic>
          No results found, please check your filter conditions
        </P>
      </Column>
    );
  }

  const canOpen = onOpen !== undefined && hasAnyScopes(openAuthorizedScopes, viewerscopes);

  // filter our null actions and ones that the user is not authorized for
  const filteredBulkActions = (
    bulkActions
      ? bulkActions.filter((action) => {
          // actions can undefined/null to skip them
          if (!action) {
            return false;
          }

          // throw if authorizedScopes is set but not viewerscopes
          if (action.authorizedScopes !== undefined && viewerscopes === undefined) {
            throw new Error(
              `Data table bulk action "${action.label}" has authorizedScopes of ${action.authorizedScopes.join(
                ", ",
              )} but viewerscopes is not set, this is a programmer mistake`,
            );
          }

          if (action.authorizedScopes !== undefined && viewerscopes !== undefined) {
            return hasAnyScopes(action.authorizedScopes, viewerscopes);
          }

          return true;
        })
      : []
  ) as DataTableAction<string[]>[];

  return (
    <>
      {stats && (
        <P tiny padLeft={2}>
          Displaying {stats.resultCount} results on {stats.pageCount > 1 ? `${stats.pageCount} pages` : "a single page"}
        </P>
      )}
      <Table {...rest}>
        <thead>
          <Tr>
            {!hideSelectRow && (
              <Th compact onClick={toggleSelectAll}>
                <Checkbox active={areAllRowsSelected} />
              </Th>
            )}
            {headers.map((header, index) => {
              const { label, ...rest } = header;

              return (
                <Th key={index} {...rest}>
                  {label}
                </Th>
              );
            })}
            <Th compact>
              {filteredBulkActions.length > 0 && (
                <ActionsDropdown
                  disabled={!isBulkActionsEnabled}
                  title={
                    isBulkActionsEnabled
                      ? "Bulk actions on selected rows"
                      : "Please select one or more rows to apply bulk actions"
                  }
                >
                  {filteredBulkActions.map((bulkAction, actionIndex) => {
                    const { label, onClick, authorizedScopes: _authorizedScopes, ...rest } = bulkAction;

                    return (
                      <DropdownItem
                        key={actionIndex}
                        loading={bulkAction.loading}
                        onClick={() => {
                          if (!onClick) {
                            return;
                          }

                          onClick(selectedRowIds);
                        }}
                        {...rest}
                      >
                        {label}
                      </DropdownItem>
                    );
                  })}
                </ActionsDropdown>
              )}
            </Th>
          </Tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => {
            const { id, cells, actions, ...rest } = row;
            const isRowSelected = selectedRowIds.includes(row.id);

            // filter our null actions and ones that the user is not authorized for
            const filteredActions = (
              actions
                ? actions.filter((action) => {
                    // actions can undefined/null to skip them
                    if (!action) {
                      return false;
                    }

                    // throw if authorizedScopes is set but not viewerscopes
                    if (action.authorizedScopes !== undefined && viewerscopes === undefined) {
                      throw new Error(
                        `Data table row action "${action.label}" has authorizedScopes of ${action.authorizedScopes.join(
                          ", ",
                        )} but viewerscopes is not set, this is a programmer mistake`,
                      );
                    }

                    if (action.authorizedScopes !== undefined && viewerscopes !== undefined) {
                      return hasAnyScopes(action.authorizedScopes, viewerscopes);
                    }

                    return true;
                  })
                : []
            ) as DataTableAction<string>[];

            return (
              <Tr highlight={isRowSelected} key={rowIndex} {...rest}>
                {!hideSelectRow && (
                  <Td onClick={() => setSelectedRowIds(toggleOption(selectedRowIds, row.id))}>
                    <Checkbox active={isRowSelected} />
                  </Td>
                )}
                {cells.map((cell, cellIndex) => {
                  const { content, linkUrl, singleRow, ...rest } = cell;

                  // limit content to single row if requested
                  const useContent = singleRow ? <SingleRow>{content}</SingleRow> : content;

                  return (
                    <Td key={cellIndex} {...rest}>
                      {linkUrl ? <Link to={linkUrl}>{useContent}</Link> : useContent}
                    </Td>
                  );
                })}
                <Td>
                  <Row overflow>
                    {filteredActions.length > 0 && (
                      <ActionsDropdown>
                        {filteredActions.map((action, actionIndex) => {
                          const { label, onClick, authorizedScopes: _authorizedScopes, ...rest } = action;

                          return (
                            <DropdownItem
                              key={actionIndex}
                              onClick={() => {
                                if (!onClick) {
                                  return;
                                }

                                onClick(id);
                              }}
                              {...rest}
                            >
                              {label}
                            </DropdownItem>
                          );
                        })}
                      </ActionsDropdown>
                    )}
                    {canOpen && onOpen && (
                      <IconButton
                        small
                        title={openButtonTitle}
                        padLeft="half"
                        icon={<InfoIcon />}
                        onClick={() => onOpen(row)}
                      />
                    )}
                  </Row>
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
});
