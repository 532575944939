import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { useEffect } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
  useFieldArray,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { LocaleEnum, TranslationMap } from "../../schema";
import { assertUnreachable } from "../../services/assertUnreachable";
import { getFieldErrors } from "../../services/getFieldErrors";
import { getLocaleName } from "../../services/getLocaleName";
import { isAuthorizationError } from "../../services/isAuthorizationErrors";
import { DateRange } from "../../services/parseDateRange";
import { toggleOption } from "../../services/toggleOption";
import { validateFileSize } from "../../validators/validateFileSize";
import { ErrorView } from "../../views/ErrorView/ErrorView";
import { NotAuthorizedView } from "../../views/NotAuthorizedView/NotAuthorizedView";
import { BlockButton } from "../BlockButton/BlockButton";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { DateField } from "../DateField/DateField";
import { Flex } from "../Flex/Flex";
import { Form } from "../Form/Form";
import { GradientPickerField } from "../GradientPickerField/GradientPickerField";
import { MarkdownField } from "../MarkdownField/MarkdownField";
import { ReorderField } from "../ReorderField/ReorderField";
import { Row } from "../Row/Row";
import { SelectField } from "../SelectField/SelectField";
import { TextArea } from "../TextArea/TextArea";
import { TextButton } from "../TextButton/TextButton";
import { TextField } from "../TextField/TextField";
import { Title } from "../Title/Title";
import styles from "./GeneratedForm.module.scss";
import {
  ArrayFieldOptions,
  CheckboxFieldOptions,
  DateFieldOptions,
  GeneratedFormProps,
  GradientPickerFieldOptions,
  MarkdownFieldOptions,
  RadioFieldOptions,
  RenderFieldOptions,
  ReorderFieldOptions,
  TextareaFieldOptions,
  TextFieldOptions,
  TranslationFieldOptions,
  UploadFieldOptions,
} from "./GeneratedForm.types";

export type FormFieldOptions =
  | TextFieldOptions
  | TextareaFieldOptions
  | MarkdownFieldOptions
  | DateFieldOptions
  | CheckboxFieldOptions
  | RadioFieldOptions
  | TranslationFieldOptions
  | UploadFieldOptions
  | ArrayFieldOptions
  | ReorderFieldOptions
  | GradientPickerFieldOptions;

export function GeneratedForm({
  loading,
  clearable,
  error: serverError,
  title,
  actions,
  sidebar,
  layout = "column",
  submitText = "Save",
  onSubmit,
  children,
  ...rest
}: GeneratedFormProps) {
  const formMethods = useForm({
    mode: "onChange",
  });
  const { formState, handleSubmit, clearErrors, reset, setError } = formMethods;
  const formRef = useRef<HTMLFormElement>(null);

  // handle validation errors from server
  useEffect(() => {
    const errors = getFieldErrors(serverError);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError(name, { type: "validate", message: errors[name]?.message });
        }
      });
    }
  }, [serverError, setError]);

  // handles form submit
  const onFormSubmit: SubmitHandler<FieldValues> = useMemo(
    () => (data) => {
      if (onSubmit) {
        onSubmit(data);
      }
    },
    [onSubmit],
  );

  // clears all clearable fields
  const clearAllFields = useMemo(
    () => () => {
      reset();
      clearErrors();

      // submit form after clearing form
      if (onSubmit) {
        handleSubmit(onSubmit)();
      }
    },
    [clearErrors, reset, handleSubmit, onSubmit],
  );

  // handle authorization errors
  if (isAuthorizationError(serverError)) {
    return <NotAuthorizedView />;
  }

  // combine server and validation errors
  const errors = getFieldErrors(serverError, formState.errors);

  // handle server errors
  if (serverError?.networkError || (serverError && Object.keys(errors).length === 0)) {
    return <ErrorView title="Oops! Something went wrong." error={serverError} />;
  }

  // expecting title when using actions
  if (actions && !title) {
    throw new Error("When providing generated form actions, please also provide a title");
  }

  // build main form body
  const formBody = (
    <Flex
      overflow
      row={layout === "grid"}
      expanded={layout === "grid"}
      className={classNames({ [styles["layout-grid"]]: layout === "grid" })}
    >
      {React.Children.map(children, (child) => (
        <Container overflow expanded={layout === "column"}>
          {child}
        </Container>
      ))}
    </Flex>
  );

  const formSubmitAndClear = (
    <>
      <BlockButton inline tertiary loading={loading} type="submit">
        {submitText}
      </BlockButton>
      {clearable && (
        <TextButton tiny onClick={() => clearAllFields()}>
          clear
        </TextButton>
      )}
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <Form ref={formRef} onSubmit={handleSubmit(onFormSubmit)} {...rest}>
        <Title actions={actions}>{title}</Title>

        {sidebar ? (
          <Row>
            <Column expanded>{formBody}</Column>
            <Column padLeft="half">{sidebar}</Column>
          </Row>
        ) : (
          formBody
        )}

        {layout === "grid" && <Column style={{ gridColumnEnd: 9 }}>{formSubmitAndClear}</Column>}

        {layout === "column" && (
          <Column marginTop="half" crossAxisAlignment="flex-end">
            {formSubmitAndClear}
          </Column>
        )}
      </Form>
    </FormProvider>
  );
}

export const FormField: React.FC<RenderFieldOptions> = (props) => {
  const { clearErrors, setValue } = useFormContext();
  const fieldType = props.field.field;

  // clears given field
  const clearField = useMemo(
    () => (field: FormFieldOptions) => {
      const clearedValue = field.field === "checkbox" ? [] : "";

      setValue(field.name, clearedValue);
    },
    [setValue],
  );

  const onClear = props.field.clearable
    ? () => {
        clearField(props.field);
        clearErrors();
      }
    : undefined;

  const field = () => {
    switch (fieldType) {
      case "text":
        return <FormTextField onClear={onClear} {...(props as RenderFieldOptions<TextFieldOptions>)} />;

      case "textarea":
        return <FormTextAreaField onClear={onClear} {...(props as RenderFieldOptions<TextareaFieldOptions>)} />;

      case "markdown":
        return <FormMarkdownField onClear={onClear} {...(props as RenderFieldOptions<MarkdownFieldOptions>)} />;

      case "date":
        return <FormDateField onClear={onClear} {...(props as RenderFieldOptions<DateFieldOptions>)} />;

      case "checkbox":
        return <FormCheckboxField onClear={onClear} {...(props as RenderFieldOptions<CheckboxFieldOptions>)} />;

      case "radio":
        return <FormRadioField onClear={onClear} {...(props as RenderFieldOptions<RadioFieldOptions>)} />;

      case "translation":
        return <FormTranslationField onClear={onClear} {...(props as RenderFieldOptions<TranslationFieldOptions>)} />;

      case "upload":
        return <FormUploadField onClear={onClear} {...(props as RenderFieldOptions<UploadFieldOptions>)} />;

      case "array":
        return <FormArrayField onClear={onClear} {...(props as RenderFieldOptions<ArrayFieldOptions>)} />;

      case "reorder":
        return <FormReorderField onClear={onClear} {...(props as RenderFieldOptions<ReorderFieldOptions>)} />;

      case "gradientpicker":
        return (
          <FormGradientPickerField onClear={onClear} {...(props as RenderFieldOptions<GradientPickerFieldOptions>)} />
        );

      default:
        assertUnreachable(fieldType, `Unexpected field type "${fieldType}" not handled`);
    }
  };

  return field();
};

const FormFields: React.FC<{ fields: FormFieldOptions[] }> = ({ fields }) => {
  return (
    <>
      {fields.map((field) => (
        <GeneratedForm.Field field={field} key={field.name} />
      ))}
    </>
  );
};

GeneratedForm.Field = FormField;
GeneratedForm.Fields = FormFields;

export const FormTextField: React.FC<RenderFieldOptions<TextFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, defaultValue, rules, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={rules}
      defaultValue={defaultValue ?? ""}
      render={({ field, formState }) => (
        <TextField
          {...fieldRest}
          {...field}
          error={formState.errors[field.name]}
          required={!!rules?.required}
          onClear={onClear}
          onInput={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }
          }}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
        />
      )}
    />
  );
};

const FormTextAreaField: React.FC<RenderFieldOptions<TextareaFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, defaultValue, rules, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={rules}
      defaultValue={defaultValue ?? ""}
      render={({ field, formState }) => (
        <TextArea
          {...fieldRest}
          {...field}
          error={formState.errors[field.name]}
          required={!!rules?.required}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
        />
      )}
    />
  );
};

const FormMarkdownField: React.FC<RenderFieldOptions<MarkdownFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, defaultValue, rules, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={rules}
      defaultValue={defaultValue ?? ""}
      render={({ field, formState }) => (
        <MarkdownField
          {...fieldRest}
          {...field}
          error={formState.errors[field.name]}
          required={!!rules?.required}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
        />
      )}
    />
  );
};

const FormDateField: React.FC<RenderFieldOptions<DateFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, defaultValue, rules, range, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, formState }) => {
        const selected = (range && Array.isArray(field.value) ? field.value[0] : field.value) as Date | null;

        return (
          <DateField
            {...fieldRest}
            {...field}
            error={formState.errors[field.name]}
            // TODO: for some reason the typing requires selectsRange to be undefined
            selectsRange={range as any}
            shouldCloseOnSelect={!range}
            selected={selected}
            required={!!rules?.required}
            startDate={range && Array.isArray(field.value) ? field.value[0] : undefined}
            endDate={range && Array.isArray(field.value) ? field.value[1] : undefined}
            onChange={(date) => {
              if (field.onChange) {
                field.onChange(date as Date);
              }

              if (onFieldChange) {
                onFieldChange(date as (Date & DateRange) | null);
              }
            }}
            onClear={onClear}
          />
        );
      }}
    />
  );
};

const FormCheckboxField: React.FC<RenderFieldOptions<CheckboxFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, rules, defaultValue: defaultValues, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={rules}
      defaultValue={defaultValues ?? []}
      render={({ field, formState }) => (
        <SelectField
          {...fieldRest}
          {...field}
          multi
          selected={field.value}
          error={formState.errors[field.name]}
          required={!!rules?.required}
          onToggle={(option) => field.onChange(toggleOption(field.value, option.value))}
          onClear={onClear}
        />
      )}
    />
  );
};

const FormRadioField: React.FC<RenderFieldOptions<RadioFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, defaultValue, rules, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, formState }) => (
        <SelectField
          {...fieldRest}
          {...field}
          selected={field.value}
          error={formState.errors[field.name]}
          required={!!rules?.required}
          onToggle={(option) => {
            if (onFieldChange) {
              onFieldChange(option);
            }

            field.onChange(option.value === field.value ? null : option.value);
          }}
          onClear={onClear}
        />
      )}
    />
  );
};

const FormTranslationField: React.FC<RenderFieldOptions<TranslationFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { clearable: _clearable, defaultValue, rules, label, onChange: onFieldChange, ...fieldRest } = field;

  // render separate text field for every locale
  return (
    <>
      {Object.keys(LocaleEnum).map((_locale) => {
        const locale = _locale as keyof TranslationMap;
        const name = `${field.name}-${locale}`;

        return (
          <Controller
            key={name}
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue ? defaultValue[locale] ?? "" : ""}
            render={({ field, formState }) => (
              <TextField
                {...fieldRest}
                {...field}
                label={`${getLocaleName(locale as LocaleEnum)} ${label.toLowerCase()}`}
                error={formState.errors[name]}
                required={!!rules?.required}
                onClear={onClear}
                onChange={(e) => {
                  if (field.onChange) {
                    field.onChange(e);
                  }

                  if (onFieldChange) {
                    onFieldChange(locale as LocaleEnum, e.target.value);
                  }
                }}
              />
            )}
          />
        );
      })}
    </>
  );
};

const FormUploadField: React.FC<RenderFieldOptions<UploadFieldOptions>> = ({ field, onClear }) => {
  const { control } = useFormContext();
  const { name, clearable: _clearable, defaultValue, rules, onChange: onFieldChange, ...fieldRest } = field;

  const useDefaultValue = defaultValue instanceof File ? defaultValue.name : "";
  let ruleSet = rules;

  if (ruleSet) {
    ruleSet.validate = validateFileSize(name);
  } else {
    ruleSet = { validate: validateFileSize(name) };
  }

  return (
    <Controller
      name={field.name}
      control={control}
      rules={ruleSet}
      defaultValue={useDefaultValue}
      render={({ field, formState }) => (
        <TextField
          {...fieldRest}
          {...field}
          type="file"
          error={formState.errors[field.name]}
          required={!!rules?.required}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e.target.files && e.target.files.length > 0 ? e.target.files[0] : null);
            }
          }}
        />
      )}
    />
  );
};

const FormArrayField: React.FC<RenderFieldOptions<ArrayFieldOptions>> = ({ field }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue: _defaultValue, ...fieldRest } = field;
  const { fields, append, remove } = useFieldArray({ control, name: field.name });

  useEffect(() => {
    // make sure array has at least one item
    if (fields.length === 0) {
      append({ value: "" });
    }
  }, [fields, append]);

  const addItem = () => append({ value: "" });

  const removeItem = (index: number) => () => remove(index);

  const fieldName = (index: number) => `${field.name}.${index}.value`;

  return (
    <Container>
      <h5>{field.label}</h5>
      {fields.map((arrayField, index) => (
        <Row key={arrayField.id} mainAxisAlignment="space-between">
          <Flex flex={1}>
            <Controller
              name={fieldName(index)}
              control={control}
              rules={field.rules}
              //@ts-ignore
              defaultValue={arrayField.value}
              render={({ field }) => (
                <TextField {...fieldRest} {...field} type="text" error={formState.errors[field.name]} />
              )}
            />
          </Flex>
          <BlockButton inline secondary small onClick={removeItem(index)}>
            Remove
          </BlockButton>
        </Row>
      ))}
      <BlockButton onClick={addItem}>Add</BlockButton>
    </Container>
  );
};

const FormReorderField: React.FC<RenderFieldOptions<ReorderFieldOptions>> = ({ field }) => {
  const { control } = useFormContext();
  const { label, onChange: onFieldChange, ...rest } = field;

  // render drag-and-drop datatable with reorderable rows
  return (
    <Flex column>
      <label
        htmlFor={field.name}
        // className={styles.label}
      >
        {label}
      </label>
      <Controller
        name={field.name}
        control={control}
        render={({ field }) => (
          <ReorderField
            {...rest}
            onReorder={(value) => {
              if (field.onChange) {
                field.onChange(value);
              }

              if (onFieldChange) {
                onFieldChange(value);
              }
            }}
          />
        )}
      />
    </Flex>
  );
};

const FormGradientPickerField: React.FC<RenderFieldOptions<GradientPickerFieldOptions>> = ({ field }) => {
  const { control } = useFormContext();
  const { defaultValue, onColorChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      render={({ field }) => (
        <GradientPickerField
          {...fieldRest}
          {...field}
          defaultValue={defaultValue}
          onColorChange={(color) => {
            if (onFieldChange) {
              onFieldChange(color);
            }
          }}
        />
      )}
    />
  );
};
